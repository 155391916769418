<template>
  <div>
    <p class="container" style="font-weight: bold; text-align: center">
      CONSULTA DE RADICADOS
    </p>
    <p class="container" style="text-align: center">
      Bienvenido al portal de la CVC donde podrá consultar el estado de sus
      peticiones, quejas, reclamos, sugerencias, denuncias y trámites
      ambientales.
    </p>
    <br />
    <div class="container">
      <div class="col-md-12">
        <b-form-group
          label="*¿El radicado que quiere consultar lo registró de forma anónima?"
          style="text-align: left"
        >
          <b-form-radio-group
            id="radio-slots"
            v-model="selectedanonimo"
            name="selectedanonimo"
          >
            <b-form-radio value="1">Si </b-form-radio>
            <b-form-radio value="2">No</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <b-form-group>
          <div class="row">
            <div class="col-md-3" style="text-align: left">
              <label v-if="selectedanonimo == 1 || selectedanonimo == 2"
                >*N° Radicado</label
              >
              <b-form-input
                v-if="selectedanonimo == 1 || selectedanonimo == 2"
                placeholder="N° Radicado"
                v-model="form.radicado"
                maxlength="15"
                type="number"
              ></b-form-input>
            </div>
            <div class="col-md-4" style="text-align: left">
              <label v-if="selectedanonimo == 2">*Identificación</label>
              <b-form-input
                v-if="selectedanonimo == 2"
                placeholder="Identificación"
                v-model="form.identificacion"
                maxlength="15"
                type="number"
              ></b-form-input>
            </div>
          </div>
        </b-form-group>
        <div class="row">
          <div class="col-md-2">
            <div v-show="show">
              <b-button
                block
                variant="success"
                v-if="selectedanonimo == 1 || selectedanonimo == 2"
                @click="enviar()"
                >Consultar</b-button
              >
            </div>
          </div>
        </div>
        <br />

        <div v-show="showtabla">
          <table class="table b-table table-striped table-responsive">
            <thead>
              <tr>
                <th>Radicado</th>
                <th>Trámite</th>
                <th>Estado</th>
                <th>Respuesta</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, ind) in items" :key="ind">
                <td>{{ item.radicado }}</td>
                <td>{{ item.tramite }}</td>
                <td v-html="item.estado"></td>
                <!-- probalo pana -->
                <td>
                  <b-button
                    variant="success"
                    :href="item.Respuesta"
                    target="blank"
                    v-show="showboton"
                    >Respuesta</b-button
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <br /><br /><br />
        <div class="text-center" v-show="loading">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
          <div class="text-center">
            <strong>Enviando la solicitud por favor espere....</strong>
          </div>
        </div>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import { rutaenviosolicitud } from "../properties";
import axios from "axios";
import swal from "sweetalert";
export default {
  data() {
    return {
      selectedanonimo: "",
      form: {
        radicado: "",
        identificacion: "",
      },
      items: [],
      estado: "",
      rutanew: "",
      rutaurl: "",
      loading: false,
      show: true,
      showtabla: false,
      showboton: false,
      selected: null,
      miHtml: `<a href="https://www.cvc.gov.co/servicio-al-ciudadano/pqrds" target="blank">Aquí</a>`,
    };
  },

  methods: {
    enviar() {
      this.arryaJson = [];
      this.items = [];
      //console.log("this.form ", this.form);
      if (this.selectedanonimo == 1) {
        this.form.identificacion = "-1";
      }

      const axiosInstance = axios.create({
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });

      if (this.validacioncampos(this.form) === true) {
        //console.log("objeto", this.form);
        this.loading = true;
        this.show = false;
        axiosInstance
          .post(rutaenviosolicitud, this.form)
          .then((response) => {
            console.log("respuesta ", response);
            this.loading = false;
            this.show = true;
            this.showtabla = true;
            if (response.data.mensaje === null) {
              if(response.data.idtipotramite == 12 || response.data.idtipotramite == 18){
                  swal("Señor(a) Usuario(a) el radicado consultado es un trámite " +
                  response.data.tramite + ", la corporación no genera respuestas para este tipo de trámite, para más información contáctenos por medio de nuestros canales ");
                  (this.selectedanonimo = ""),
                  (this.form = {
                    radicado: "",
                    identificacion: "",
                  });
                this.showtabla = false;
              } else {
                  var estado = "";
              if (response.data.estado === "ANULADA") {
                estado =
                  "Señor(a) Usuario(a) el estado de su radicado es ANULADO, para más información contáctenos por medio de nuestros canales " +
                  this.miHtml;
              } else if (response.data.estado === "EN PROCESO") {
                estado =
                  "Señor(a) Usuario(a) el estado de su radicado está en PROCESO, para más información contáctenos por medio de nuestros canales " +
                  this.miHtml;
              } else if (response.data.estado === "SOLUCIONADA") {
                estado =
                  "Señor(a) Usuario(a) el estado de su radicado es SOLUCIONADA";
              }
              if (response.data.rutaadjunto !== null) {
                if (response.data.rutaadjunto.includes("=")) {
                  var arr = response.data.rutaadjunto.split("=");
                  //console.log("this.rutanew 178 ", arr);

                  arr = arr.filter((el) => el != "");

                  arr.forEach((ruta) => {
                    var url = `${ruta}`;

                    url = url.replace(/\//g, ",");

                    url =
                      "https://cvc-fileback.arqbs.com/restFileController/obtenerArchivoAlmacenado/" +
                      url.replace(/\//g, ",");

                    var objeto = {
                      radicado: response.data.radicado,
                      tramite: response.data.tramite,
                      estado: estado,
                      Respuesta: url,
                    };

                    this.items.push(objeto);
                  });
                  this.showboton = true;
                  (this.selectedanonimo = ""),
                    (this.form = {
                      radicado: "",
                      identificacion: "",
                    });
                }
              } else {
                this.items = [
                  {
                    radicado: response.data.radicado,
                    tramite: response.data.tramite,
                    estado: estado,
                  },
                ];
                this.showboton = false;
                (this.selectedanonimo = ""),
                  (this.form = {
                    radicado: "",
                    identificacion: "",
                  });
              }
              }
              
            } else {
              if (response.data.mensaje !== null) {
                swal("Respuesta", response.data.mensaje);
                (this.selectedanonimo = ""),
                  (this.form = {
                    radicado: "",
                    identificacion: "",
                  });
                this.showtabla = false;
              }
            }
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        console.log("fallo");
      }
    },
    validacioncampos() {
      if (this.form.radicado === "") {
        swal("Debe ingresar un número de radicado");
      } else if (this.form.identificacion === "") {
        swal("Debe ingresar un número de identificación");
      } else {
        return true;
      }
    },
  },
  components: {},
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #000000;
}
</style>
